angular.module('nwui-toolkit').directive('nwuiCompoundModalAcceptRiskProfileChange', [
  '$timeout',
  '$filter',
  '$q',
  'NwuiModalV2Factory',
  'NwuiToastFactory',
  'NwuiCardHeaderV2Factory',
  'NwuiFormV3Factory',
  'NwuiSigningService',
  'NwuiPageScrollService',
  'NwuiCardTableV3Factory',
  'NwuiCardTableFactory',
  'NwuiToastManagerService',
  'NwuiInputService',
  'NwuiFormV3Service',
  'FundFactsService',
  'ApiAccountsService',
  'ApiClientsService',
  'NwuiAllocationFactory',
  'NwuiButtonV3Factory',
  'locale',
  'NwButtonService',
  function(
    $timeout,
    $filter,
    $q,
    NwuiModalV2Factory,
    NwuiToastFactory,
    NwuiCardHeaderV2Factory,
    NwuiFormV3Factory,
    NwuiSigningService,
    NwuiPageScrollService, 
    NwuiCardTableV3Factory,
    NwuiCardTableFactory,
    NwuiToastManagerService,
    NwuiInputService,
    NwuiFormV3Service,
    FundFactsService,
    ApiAccountsService,
    ApiClientsService,
    NwuiAllocationFactory,
    NwuiButtonV3Factory,
    locale,
    NwButtonService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: '/compound/modal/accept-risk-profile-change/template.html',
      scope: {
        open: '=',
        resourceCb: '=resourceCb',
        submitCb: '=submitCb',
        riskProfileData: '=',
        accountData: '=',
        allowCancel: '='
      },
      link: function(scope, element, attrs) {
        scope.showFundFactApprovalForm = window.organization_data.port_data.show_fund_facts_before_rp_approval;

        scope.data = {
          open: false
        }
        scope.activeAccountIndex = 0;
        scope.riskProfiles = [];
        scope.accounts = [];
        
        scope.cardHeader = new NwuiCardHeaderV2Factory({
          name_i18n: "accept_risk_profile.header",
          name_i18n_data: {
            org_name_short: organization_data.name_short
          },
          level: 2,
          icon: {
            name: 'info'
          }
        });

        scope.subheaders = {
          old_alloc: new NwuiCardHeaderV2Factory({
            name_i18n: "accept_risk_profile.oldPortfolio",
            level: 2,
            icon: {
              name: 'donut_large'
            }
          }),
          new_alloc: new NwuiCardHeaderV2Factory({
            name_i18n: "accept_risk_profile.newPortfolio",
            level: 2,
            icon: {
              name: 'donut_large'
            }
          })
        }

        scope.approveButton = new NwuiButtonV3Factory({
          id: 'accept-risk-profile-change-approve-button',
          text_i18n: 'accept_risk_profile.buttons.submit',
          color: '',
          icon: {
            name: 'check'
          },
          stretch: true,
          onClick: function() {       
            if(scope.showFundFactApprovalForm) {
              var isFormValid = NwuiFormV3Service.validate('accept-risk-profile-change-form');
              if(isFormValid === true) {
                NwButtonService.buttons['accept-risk-profile-change-approve-button'].processing = true;
                approveRiskProfile();
              }
            } else {
              NwButtonService.buttons['accept-risk-profile-change-approve-button'].processing = true;
              approveRiskProfile();
            }
          }
        });

        scope.cancelButton = new NwuiButtonV3Factory({
          id: 'accept-risk-profile-change-cancel-button',
          text_i18n: 'accept_risk_profile.buttons.cancel',
          color: 'error',
          icon: {
            name: 'undo'
          },
          stretch: true,
          onClick: function() {
            scope.data.open = false; 
          }
        });

        scope.modal = new NwuiModalV2Factory({
          open: false,
          wide: true,
          locked: !scope.allowCancel
        });

        scope.$watchCollection('[riskProfileData, accountData]', function(newRiskProfileAndAccountData) {
          if(newRiskProfileAndAccountData[0] && newRiskProfileAndAccountData[1]) {
            createRiskProfileAndAccountObjects();
            $timeout(function() {
              scope.data.open = true;
            })
          }
        });

        // scope.$watch('open', function(new_val) {

        // });

        function approveRiskCallbackFn() {
          NwButtonService.buttons['accept-risk-profile-change-approve-button'].processing = false;
          scope.activeAccountIndex++;
          if(scope.activeAccountIndex > scope.riskProfiles.length - 1) { 
            scope.submitCb(); 
            scope.data.open = false;
          }
        }

        function approveRiskProfile() {
          if(scope.accountData.length > 0) {
            ApiAccountsService.approveRiskProfile(
              scope.accountData[scope.activeAccountIndex].id, 
              scope.riskProfileData[scope.activeAccountIndex].new.kyc_instance._id
            ).then(function(){
              approveRiskCallbackFn();
            }, function(err) {

              var text_i18n = 'nwui.common.error.unknown';

              if(err && err.data) {
                switch(err.data.code) {
                  case 4310: 
                    text_i18n = 'manage.risk_profiles.edit.review.error.4310';
                    break;
                }
              }

              NwuiToastManagerService.register(
                new NwuiToastFactory({
                  text_i18n: text_i18n,
                  type: 'error',
                  icon: {
                    name: 'close'
                  }
                }
              ));

              approveRiskCallbackFn();
            });
          } else {
            approveRiskCallbackFn();
          }
        }

        function createCardForm(riskProfile, accountId) {
          if(scope.showFundFactApprovalForm) {
            return new NwuiFormV3Factory({
              name: 'accept-risk-profile-change-form',
              onSubmit: function() {},
              onComplete: function(obj) {
              },
              submit_button: false,
              steps: [
                {
                  description_i18n: 'nwui.modal.create_eft.fund_facts_desc',
                  descriptionFunction: function() {
                    FundFactsService.getFundFactArray(accountId, riskProfile.new.kyc_instance._id).then(function(fundFactArray) {
                      fundFactArray.forEach(function(cusip) {
                        window.open(locale.getString('nwui.common.asset.fund.' + cusip + '.facts_url'));
                      });
                    });
                  },
                  columns: [{
                    directive: 'input',
                    width: 100,
                    data: {
                      directive: 'checkbox',
                      data: {
                        id: 'accept-risk-profile-change-modal-input-approve-risk-profile',
                        label_i18n: " ",
                        stretch: true,
                        required: true,
                        options: [{
                          label_i18n: "transfers.new.investment.risk_profile.input.option_label",
                          model: 'approve',
                          value: false,
                          required: true
                        }],
                        size: '',
                        post_var: 'risk_profile',
                        onChange: function(val) {

                          if(val == undefined || val == '') 
                            return false;
                        }
                      }
                    }
                  }]
                }
              ]
            })
          }
        }

        function setLanguageKey(val){
          if(!val) return undefined;

          return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
        }

        function createAllocation(riskProfile) {

          if(riskProfile.pie_data.data[0].dimensions) {
            return {
              name: riskProfile.pie_data.name,
              series: riskProfile.pie_data.data[0].dimensions.map(function(datum,i) {
                return {
                  name: datum.key,
                  percent: datum.y,
                  type: (datum.key.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type
                }
              })
            }
          } else {
            return {
              name: riskProfile.pie_data.name,
              series: riskProfile.pie_data.data.map(function(datum,i) {
                return {
                  name: datum.description,
                  percent: datum.percent,
                  type: (datum.description.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type
                }
              })
            }
          }

          // if(riskProfile.pie_data.data[0].dimensions) {
          //   dimensions = riskProfile.pie_data.data[0].dimensions;
          // } else {
          //   dimensions = riskProfile.pie_data.data;
          // }

          // return {
          //   name: riskProfile.pie_data.name,
          //   series: dimensions.map(function(datum,i) {
          //     return {
          //       name: datum.key || datum.description,
          //       percent: datum.y || datum.percent,
          //       type: datum.type
          //     }
          //   }),
          //   decimal: 0
          // };
          
        }
        
        function createRiskProfileAndAccountObjects() {
          if(scope.riskProfileData) {
            scope.riskProfiles = [];
            scope.accounts = [];
            scope.riskProfileData.forEach(function(riskProfile, index) {
              var accountDataAtIndex = scope.accountData[index] ? scope.accountData[index].id : undefined;
              
              var oldPortfolioNameKey = 'nwui.common.asset.portfolios.' + setLanguageKey(riskProfile.old.pie_data.name);
              var newPortfolioNameKey = 'nwui.common.asset.portfolios.' + setLanguageKey(riskProfile.new.pie_data.name);
              var oldPortfolioName = locale.isTranslated(oldPortfolioNameKey) ? locale.getString(oldPortfolioNameKey) : '';
              var newPortfolioName = locale.isTranslated(newPortfolioNameKey) ? locale.getString(newPortfolioNameKey) : '';
              scope.riskProfiles.push({
                oldPortfolioName: riskProfile.old ? oldPortfolioName : undefined,
                newPortfolioName: riskProfile.new ? newPortfolioName : undefined,
                //mixTable: createMixTable(activeRiskProfile),
                oldAllocation: riskProfile.old ? createAllocation(riskProfile.old) : undefined,
                newAllocation: riskProfile.new ? createAllocation(riskProfile.new) : undefined,
                form: createCardForm(riskProfile, accountDataAtIndex)
              });

                
            });
            scope.accountData.forEach(function(account, index) {
              scope.accounts.push({
                accountType: account.account_type,
                accountNumber: account.custodian_id,
              });
            });

          }
        }



      } 
    }
  }
]); 
