angular.module('nwui-compound-risk-profile-editor.directives', [])

.directive('nwuiCompoundRiskProfileEditor', [
	'$timeout',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwuiFormV3Factory',
	function(
		$timeout,
		NwuiInputService,
		NwuiFormV3Service,
		NwuiFormV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: '/compound/risk-profile-editor/template.html',
			scope: {
				current_risk_profile: '=currentRiskProfile',
				new_risk_profile: '=newRiskProfile',
				resource_id: '=resourceId',
				is_admin: '=?isAdmin',
				questionnaire_toggle_default: '=?questionnaireToggleDefault',
				previewResource: '=?previewResource',
				previewResourceCb: '=?previewResourceCb',
				previewResourceMap: '=?previewResourceMap',
				validateForm: '=?validateForm',
				getFormData: '=?getFormData'
			},
			compile: function(element, attrs) {
				return {
					pre: function(scope, element, attrs) {

						if(
							scope.questionnaire_toggle_default !== 'no' &&
							scope.questionnaire_toggle_default !== 'yes'
						) {
							scope.questionnaire_toggle_default = 'yes';
						}

						if(angular.isUndefined(scope.is_admin)) scope.is_admin = false;

						scope.data = {
							can_preview: (((organization_data || {}).port_data || {}).accounts || {}).show_risk_profile_preview,
							always_expanded: (((organization_data || {}).port_data || {}).accounts || {}).show_review_risk_profile,
							show_risk_profile: (((organization_data || {}).port_data || {}).accounts || {}).show_review_risk_profile || scope.is_admin || (scope.questionnaire_toggle_default === 'no')
						}

					},
					post: function(scope, element, attrs) {

						var data = {
							full_form_answers: {},
							default_answers: undefined
						}

						var parseConditions = function(ng_if) {

							if (ng_if.or) {
								return ng_if.or.some(function (item) {
									return parseIndividualCondition(item);
								});
							} else if (ng_if.and) {
								return ng_if.and.every(function (item) {
									return parseIndividualCondition(item);
								});
							} else {
								return parseIndividualCondition(ng_if);
							}

						}

						var parseIndividualCondition = function(item) {

							if(item.ng_if_model) {

								var value = item.ng_if_value_model === undefined
									? item.ng_if_value
									: data.full_form_answers[item.ng_if_value_model];

								switch(item.ng_if_operator) {
									case 'gt':
										return data.full_form_answers[item.ng_if_model] > value;
										break;
									case 'gte':
										return data.full_form_answers[item.ng_if_model] >= value;
										break;
									case 'lt':
										return data.full_form_answers[item.ng_if_model] < value;
										break;
									case 'lte':
										return data.full_form_answers[item.ng_if_model] <= value;
										break;
									case 'ne':
										return data.full_form_answers[item.ng_if_model] != value;
										break;
									default:
										return data.full_form_answers[item.ng_if_model] == value;
								}

							} else if (item.or) {
								return parseConditions(item);
							} else if (item.and) {
								return parseConditions(item);
							} else {
								return data.full_form_answers[item.ng_if_model] == item.ng_if_value;
							}

						}

						var checkShow = function(step_index, test) {


							var show = true;

							if(test.ng_if) {
								show = parseConditions(test.ng_if);
							}

							//if question
							if(test && test.input) {
								NwuiInputService.inputs['risk-profile-editor-questionnaire-form-input-' + test.step_index + '-' + test.input.model].hide = !show;
							}
							//if step
							else {
								scope.module.form.questionnaires[test.model].show = show;
							}

						}

						var applyWatchersLoop = function(ng_if, questions, test, map, options = {
							nonQuestionTombstones: []
						}) {
							var nonQuestionTombstones = options.nonQuestionTombstones;

							if(ng_if.and) {

								return applyWatchersLoop(ng_if.and, questions, test, map, { nonQuestionTombstones});

							} else if(ng_if.or) {

								return applyWatchersLoop(ng_if.or, questions, test, map, { nonQuestionTombstones});

							} else if(ng_if.ng_if_model) {

								//if question
								if(test && test.input) {

									//check if model belongs to any checkbox options
									questions.forEach(function(q) {
										if(q.input.input_type === 'check') {

											q.input.options.forEach(function(option) {
												if(ng_if.ng_if_model === option.model && q.question_watchers.indexOf(test) === -1) {
													q.question_watchers.push(test);
												}
											});

										}
									});

									//apply watchers
									if(questions[map[ng_if.ng_if_model]] && questions[map[ng_if.ng_if_model]].question_watchers.indexOf(test) === -1)
										questions[map[ng_if.ng_if_model]].question_watchers.push(test);

								}
								//if step
								else {

									//check if model belongs to any checkbox options
									questions.forEach(function(q) {
										if(q.input.input_type === 'check') {

											q.input.options.forEach(function(option) {
												if(ng_if.ng_if_model === option.model && q.step_watchers.indexOf(test) === -1) {
													q.step_watchers.push(test);
												}
											});

										}
									});

									//apply watchers
									if(
										questions[map[ng_if.ng_if_model]] &&
										questions[map[ng_if.ng_if_model]].step_watchers.indexOf(test) === -1){
											questions[map[ng_if.ng_if_model]].step_watchers.push(test);
										}

									// each question needs to watch a specific tombstone that is not associated to a question, like "score"
									if (nonQuestionTombstones.indexOf(ng_if.ng_if_model) !== -1){
										questions.forEach( q => {
											if (
												q.step_watchers.indexOf(test) === -1
											){
												q.step_watchers.push(test);
											}
										})
									}
								}

							} else if(ng_if) {

								for(var i = 0; i < ng_if.length; i++) {
									applyWatchersLoop(ng_if[i], questions, test, map, { nonQuestionTombstones});
								}
							}

						}

						var mergeIfs = function(row, field) {

							//if row if is empty
							if(!row.ng_if || angular.equals(row.ng_if, {})) return;

							if(!field.ng_if || angular.equals(field.ng_if, {})) {
								field.ng_if = row.ng_if;
							} else {
								field.ng_if = {
									and: [
										Object.assign({}, field.ng_if),
										row.ng_if
									]
								}
							}

						}

						var applyQuestionWatchers = function(steps) {

							var map = {};
							var questions = [];
							steps.forEach(function(step,i) {

								step.rows.forEach(function(row,j) {

									row.fields.forEach(function(field,k) {
										mergeIfs(row, field);
										field.question_watchers = [];
										field.step_index = i;
										questions.push(field);
										map[field.input.model] = questions.length-1;
									});

								});

							});


							//build map from array
							// var map = {};
							// for(var i = 0; i < questions.length; i++) {
							// 	questions[i].question_watchers = [];
							// 	map[questions[i].input.model] = i;
							// }

							questions.forEach(function(question, i) {

								//loop through if conditions
								applyWatchersLoop(question.ng_if, questions, question, map);

							})

						}

						var applyStepWatchers = function(questions, steps, nonQuestionTombstones = []) {

							//build map from array
							var map = {};
							for(var i = 0; i < questions.length; i++) {
								questions[i].step_watchers = [];
								map[questions[i].input.model] = i;
							}

							steps.forEach(function(step, i) {
								step.model = i;

								//loop through if conditions
								applyWatchersLoop(step.ng_if, questions, step, map, {
									nonQuestionTombstones
								});
							})

						}

						var check_ng_if = function(ng_if, field, models, new_models) {

							if(ng_if.and) {

								check_ng_if(ng_if.and, field, models, new_models);

							} else if(ng_if.or) {

								check_ng_if(ng_if.or, field, models, new_models);

							} else if(ng_if.ng_if_model) {

								// if($scope.data.single_account_select) {

								// 	if(models.indexOf(ng_if.ng_if_value) !== -1)
								// 		new_models.push(field.input.model);

								// } else {

									if(models.indexOf(ng_if.ng_if_model) !== -1)
										new_models.push(field.input.model);

								// }

							} else if(ng_if) {

								for(var i = 0; i < ng_if.length; i++) {
									check_ng_if(ng_if[i], field, models, new_models);
								}

							}

						}

						var check_ng_if_step = function(ng_if, step_index, models, new_models) {

							if(ng_if.and) {

								check_ng_if_step(ng_if.and, step_index, models, new_models);

							} else if(ng_if.or) {

								check_ng_if_step(ng_if.or, step_index, models, new_models);

							} else if(ng_if.ng_if_model) {

								//if match
								// if($scope.data.single_account_select) {

								// 	if(models.indexOf(ng_if.ng_if_value) !== -1)
								// 		new_models.push(step_index);

								// } else {

									if(models.indexOf(ng_if.ng_if_model) !== -1)
										new_models.push(step_index);

								// }

							} else if(ng_if) {

								for(var i = 0; i < ng_if.length; i++) {
									check_ng_if_step(ng_if[i], step_index, models, new_models);
								}

							}

						}

						var loadAllocation = function(pie_data, version) {

							if(version !== 'current' && version !== 'new') return;

							scope.module.allocation[version].name = pie_data.name;

							//ACCOUNT FOR TWO DIFFERENT RISK PROFILE RESPONSE DATA STRUCTURES... DAFUQ?
							if(pie_data.data[0].dimensions) {
								scope.module.allocation[version].series = pie_data.data[0].dimensions.map(function(datum,i) {
									return {
										name: datum.key,
										percent: datum.y,
										type: (datum.key.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type
									}
								});
							} else {
								scope.module.allocation[version].series = pie_data.data.map(function(datum,i) {
									return {
										name: datum.description,
										percent: datum.percent,
										type: (datum.description.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type
									}
								});
							}

						}

						var checkSteps = function(field, step_index){
							if (field.step_watchers.length > 0 ){
								field.step_watchers.forEach(function (step) {
									checkShow(step_index, step);
								})
							}

							if (field.question_watchers.length > 0) {
								field.question_watchers.forEach(function (field) {
									checkShow(step_index, field);
								});
							}
							return;
						}

						var loadRiskProfile = function(kyc_instance, kyc_answers) {

							var client_submitted_date = kyc_instance.client_submitted_date;
							var pm_submitted_date = kyc_instance.pm_submitted_date;
							var valid_date;

							if(client_submitted_date && pm_submitted_date){
								valid_date = (client_submitted_date > pm_submitted_date) ? client_submitted_date : pm_submitted_date;
							} else {
								valid_date = client_submitted_date || pm_submitted_date;
							}

							if(valid_date) {

								scope.module.header.allocation.current.button = {
									id: 'manage-risk-profile-editor-last-modified-button',
									hollow: true,
									text_i18n: 'nwui.compound.risk_profile_editor.allocation.header_button',
									text_i18n_data: {
										validDate : moment(valid_date).format('MMM DD, YYYY')
									},
									onClick: function() {}
								}

								// scope.module.header.allocation.current.button.text_i18n = 'users.sections.overview.risk_card.subtitle';
								// scope.module.header.allocation.current.button.text_i18n_data = {
								// 	validDate : moment(valid_date).format('MMM DD, YYYY')
								// }

							}

							updateCurrentRiskProfile(kyc_answers, kyc_instance.display_score);
              applyQuestionWatchers(kyc_instance.steps);

							var forms = [];
							kyc_instance.steps.forEach(function(step, step_index) {
								var columns = [];
								step.rows.forEach(function(row,j) {
									applyStepWatchers(row.fields, kyc_instance.steps, ["score"]);

									row.fields.forEach(function(field, k) {
										// mergeIfs(row, field);
										// applyQuestionWatchers(kyc_instance.steps);
										// applyStepWatchers(row.fields, kyc_instance.steps);

										// check if questions need to be reviewed / or are undefined
										if(
											field.input.is_review_required === false ||
											field.input.model === undefined
										) return;

										var value = kyc_answers[field.input.model];

										// set input types for each question
										var input_type = 'text';
										switch (field.input.input_type) {
											case 'radio':
											case 'select':
												input_type = 'select';
												break;
											case 'check':
												input_type = 'checkbox';
												break;
											case 'disclaimer':
												input_type = 'disclaimer';
												break;
											case 'subheading':
												input_type = 'subheading';
												break;
											case 'input':
											default:
												input_type = 'text';
												break;
										}

										// get values for options in applicable input types
										if(field.input.input_type == 'check') {
											value = field.input.options = field.input.options.map(function(option,i) {

												var value = false;

												if(kyc_answers[field.input.model]) {

													var index = 0;
													kyc_answers[field.input.model].forEach(function(answer_option,j) {
														if(answer_option.model === option.model)
															index = j;
													});

													value = kyc_answers[field.input.model][index].value;

												}

												return {
													label_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.model,
													model: option.model,
													value: value
												}
											});
										} else if(input_type == 'select') {
											field.input.options.forEach(function(option,k) {
												option.text_i18n = 'nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.value;
											});
										}

										columns.push({
											width: 100,
											directive: 'input',
											data: {
												directive: input_type,
												data: {
													label_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.input.label',
													model: value,
													id: 'risk-profile-editor-questionnaire-form-input-' + step_index + '-' + field.input.model,
													type: (input_type === 'text') ? 'text' : 'stacked',
													placeholder_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.input.placeholder',
													options: field.input.options,
													stretch: true,
													required: field.input.optional !== true,
													size: 'small',
													post_var: field.input.model,
													default_value: value,
													hide: field.input.hide,
													mask: field.input.validate === 'horizon' ? field.input.validate : '',
													validators: field.input.validate === 'horizon' ? [field.input.validate] : [],
													onChange: function(model) {
														switch (input_type) {
															case 'select':
															case 'radio':
																var post_var = this.post_var;
																this.options.forEach(function (option) {
																	if(option.value === model) {
																		data.full_form_answers[post_var] = option.value;
																	}
																});

																if(scope.previewResource && scope.previewResourceMap) {
																	var form_data = scope.getFormData();
																	form_data[post_var] = model;

																	scope.previewResource(scope.resource_id, form_data)
																		.then(function(obj) {
																			isApiResponded = true;
																			if(scope.previewResourceCb)
																				scope.previewResourceCb(obj);

																			var risk_profile = scope.previewResourceMap(obj)

																			if(risk_profile.kyc_answers)
																				updateCurrentRiskProfile(risk_profile.kyc_answers, risk_profile.kyc_instance.display_score);
																			if(risk_profile.pie_data)
																				loadAllocation(risk_profile.pie_data, 'new');

																			checkSteps(field, step_index)
																		})
																}

																break;
															case 'checkbox':
																this.options.forEach(function(option) {
																	model.forEach(function (item) {
																		if (option.model === item.model && data.full_form_answers[option.model] !== item.value) {
																			data.full_form_answers[option.model] = item.value;
																		}
																	});
																});
																break;
															case 'disclaimer':
															case 'subheading':
																break;
															default:
																data.full_form_answers[this.post_var] = model;
														}
														checkSteps(field, step_index);
													}
												}
											}
										});

										if(field.question_watchers && field.question_watchers.length > 0) {
											(function(step_index) {
												$timeout(function() {
													field.question_watchers.forEach(function(field) {
														checkShow(step_index, field);
													})
												}, 0);
											})(step_index);
										}

										if(field.step_watchers && field.step_watchers.length > 0) {
											(function(step_index) {
												$timeout(function() {
													field.step_watchers.forEach(function(step) {
														checkShow(step_index, step);
													})
												}, 0);
											})(step_index);
										}
									});
								});

								if(!columns || columns.length === 0) return;
								forms.push(
									new NwuiFormV3Factory({
										show: true,
										name: 'risk-profile-editor-questionnaire-form-'+step_index,
										onSubmit: function(post_data) {},
										onComplete: function(obj) {},
										submit_button: false,
										steps: [{
											title_i18n: 'nwui.questionnaire.steps.'+(step_index+1)+'.title',
											description_i18n: 'nwui.questionnaire.steps.'+(step_index+1)+'.subtitle',
											columns: columns
										}]
									})
								);
							});

							scope.module.form.questionnaires = forms;

						}

						function mergeObjects() {
							var resObj = {};
							for(var i=0; i < arguments.length; i += 1) {
								var obj = arguments[i],
								keys = Object.keys(obj);

								for(var j=0; j < keys.length; j += 1) {
									resObj[keys[j]] = obj[keys[j]];
								}
							}
							return resObj;
						}

						var updateCurrentRiskProfile = function(kyc_answers, display_score) {

              data.full_form_answers = mergeObjects(kyc_answers, { display_score: display_score, score: display_score });

							for(var i = 0; i < scope.module.form.questionnaires.length; i++) {
								for(var key in kyc_answers) {
									if(NwuiInputService.inputs['risk-profile-editor-questionnaire-form-input-' + i + '-' + key])
										NwuiInputService.inputs['risk-profile-editor-questionnaire-form-input-' + i + '-' + key].model = kyc_answers[key];
								}
							}

							scope.module.header.questionnaire.button.text_i18n_data = {
								score: Math.round(data.full_form_answers['display_score'] * 100) / 100
							}

						}

						scope.validateForm = function() {

							var valid = true;
							if(scope.data.show_risk_profile) {
								// will look at the updated answers that was done inside `updatePortfolioService'

								for(var i = 0; i < scope.module.form.questionnaires.length; i++) {
									if(scope.module.form.questionnaires[i].show)
										valid = NwuiFormV3Service.validate(scope.module.form.questionnaires[i].name);

									if(valid !== true)
										break;
								}
							}

							return valid;

						}

						scope.getFormData = function() {
							var form_data = Object.assign({}, data.default_answers);
							if(!scope.data.show_risk_profile)
								return form_data;

							for(var i = 0; i < scope.module.form.questionnaires.length; i++) {
								if(scope.module.form.questionnaires[i].show)
									form_data = Object.assign(form_data, NwuiFormV3Service.getFormData(scope.module.form.questionnaires[i].name))
							}
							return form_data;

						}

						scope.module = {
							header: {
								allocation: {
									current: {
										name_i18n: "nwui.compound.risk_profile_editor.allocation.current",
										level: 2,
										icon: {
											name: 'donut_large'
										},
										button: undefined
									},
									new: {
										name_i18n: "nwui.compound.risk_profile_editor.allocation.new",
										level: 2,
										icon: {
											name: 'donut_large'
										}
									}
								},
								questionnaire: {
									name_i18n: 'nwui.compound.risk_profile_editor.questionnaire.header',
									level: 2,
									button: {
										id: 'manage-risk-profile-editor-score-button',
										icon: {
											name: 'stars'
										},
										hollow: true,
										text_i18n: 'nwui.compound.risk_profile_editor.questionnaire.header_button',
										// text_i18n_data: undefined,
										onClick: function() {}
									},
									icon: {
										name: 'assignment'
									}
								}
							},
							allocation: {
								current: {
									series: [],
									decimal: 0
								},
								new: {
									series: [],
									decimal: 0
								}
							},
							form: {
								review: new NwuiFormV3Factory({
									name: 'risk-profile-editor-review-form',
									onSubmit: function(post_data) {},
									onComplete: function(obj) {},
									submit_button: false,
									steps: [{
										columns: [{
											directive: 'input',
											width: 100,
											data: {
												directive: 'radio',
												data: {
													type: 'stacked',
													model: scope.data.always_expanded ? 'no' : scope.questionnaire_toggle_default,
													id: 'risk-profile-editor-review-form-has-changes',
													stretch: true,
													required: true,
													options: [{
														label_i18n: "nwui.compound.risk_profile_editor.form.review.yes",
														value: 'yes'
													}, {
														label_i18n: "nwui.compound.risk_profile_editor.form.review.no",
														value: 'no'
													}],
													onChange: function(model) {

														scope.data.show_risk_profile = (model === 'no');

													},
													post_var: 'hasChanges'
												}
											}
										}]
									}]
								}),
								questionnaires: []
							},
							cta: {
								show_risk_profile: {
									id: 'manage-risk-profile-editor-show-risk-profile-button',
									text_i18n: 'nwui.compound.risk_profile_editor.cta.show_risk_profile',
									color: '',
									size: 'small',
									hollow: true,
									icon: {
										name: 'edit'
									},
									onClick: function() {
										scope.data.show_risk_profile = NwuiInputService.inputs['risk-profile-editor-review-form-has-changes'].model = 'no';
									}
								}
							}
						}

						scope.$watch('current_risk_profile', function(new_val, old_val) {

							if(new_val === undefined) return;

							if(new_val.pie_data) {
								loadAllocation(new_val.pie_data, 'current');
							}

							if(new_val.kyc_instance && new_val.kyc_answers) {
								data.default_answers = Object.assign({}, new_val.kyc_answers);
							// 	loadCurrentRiskProfile(new_val.kyc_instance, new_val.kyc_answers);
							}

						})

						scope.$watch('new_risk_profile', function(new_val, old_val) {

							if(new_val === undefined) return;

							if(new_val.pie_data) {
								loadAllocation(new_val.pie_data, 'new');
							}

							if(new_val.kyc_instance && new_val.kyc_answers) {
								// data.default_answers = Object.assign({}, new_val.kyc_answers);
								loadRiskProfile(new_val.kyc_instance, new_val.kyc_answers);
							}

						})

					}
				}
			}
		}
	}
]);